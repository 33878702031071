<template>
  <div class="w-sm-100 w-xs-100 ">
    <div class="md-trading-content">
      <h3>Profile Page</h3>
      <p class="text-error text-small">*all fields below are required. </p>

      <!--Fist name & last name-->
      <vs-row class="mb-2">
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Full legal first name
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="senderFirstAndMiddleName"
              >
            </div>
            <div v-if="validationSenderFirstAndMiddleNameError" class="text-error text-small">Please fill in your
              first & middle
              name</div>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Full legal last name
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="senderLastName"

              >
            </div>
            <div v-if="validationSenderLastNameError" class="text-error text-small">Please fill in your last
              name</div>
          </div>

        </vs-col>

      </vs-row>

      <!--Date of Birth & Phone-->
      <vs-row class="mb-2">
        <vs-col vs-w="6"  vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Date of Birth
            </label>
            <div class="input-group">
              <input type="date" id="sender-date-of-birth" name="sender-date-of-birth"
                     class="form-control" :max="maxDate" v-model="senderDateOfBirth">

            </div>
            <small v-if="validationSenderDateOfBirthError" class="text-error text-small">Please enter your date of
              birth</small>
            <small v-else-if="validationSenderDateOfBirthMinimumAgeError" class="text-error text-small">Your date of birth should be before {{maxDate}}</small>
          </div>
        </vs-col>
        <vs-col vs-w="6"  vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Phone Number
            </label>
            <div class="input-group">
              <vue-tel-input id="sender-phone" v-model="senderPhone" class=""
                             v-bind="phoneValidation" @validate="phoneValidationMethod"></vue-tel-input>
            </div>
            <small v-if="validationSenderPhoneError" class="text-error text-small">Please enter a valid contact
              number (prefix with +CountryCode)</small>
          </div>

        </vs-col>

      </vs-row>

      <h4 class="mt-4">Your Address</h4>
      <hr>

      <!--Address-->
      <vs-row class="mb-2">
        <vs-col vs-w="12" vs-xs="12" class="p-1">
          <div class="pr-1">
            <label class="control-label">
              Residential Address
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="senderAddress"
              >

            </div>
            <div v-if="validationSenderAddressError" class="text-error text-small">Please enter your
              address</div>
          </div>
        </vs-col>

      </vs-row>
      <!-- City & Postal Code-->
      <vs-row class="mb-2">
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div >
            <label class="control-label">
              City
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="senderCity"
              >
            </div>
            <small v-if="validationSenderCityError" class="text-error text-small">Please enter a city</small>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-xs="12" class="p-1">
          <div>
            <label class="control-label">
              Postal Code
            </label>
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     v-model="senderPostalCode"
              >
            </div>
            <small v-if="validationSenderPostalCodeError" class="text-error text-small">Please enter your postal
              code</small>
          </div>

        </vs-col>

      </vs-row>
      <!--Country-->
      <vs-row class="mb-2">
        <vs-col vs-w="12" class="p-1">
          <div class="pr-1">
            <label class="control-label">
              Country
            </label>
            <div class="input-group">
              <v-select :options="countryList"
                        :clearable="false"
                        id="sender-country"
                        label="name"
                        class="w-100"
                        v-model="senderCountry"
                        :reduce="item => item.countryCode"
                        style="background-color: #FFFFFF"
              >
                <template v-slot:option="item">
                  <country-flag :country="item.countryCode.toLowerCase()"
                                size="small"></country-flag>
                  {{item.name}}
                </template>
              </v-select>
            </div>
            <small v-if="validationSenderCountryError" class="text-error text-small">Please select a
              country</small>
          </div>
        </vs-col>
      </vs-row>

      <br>
      <vs-row class="mb-2">
        <button class="w-100 btn-trading-positive" @click="submitProfile()">Submit Profile</button>
      </vs-row>

      <vs-row class="mb-2" vs-type="flex" vs-justify="center">

        <p class="text-center text-success" v-if="isProfileSubmitted">Profile Submitted ! <a @click="goBack()" class="pointer">Go Back</a></p>
        <p class="text-center text-error" v-else-if="profileSubmissionError">Error in submitting profile. Please try again.</p>
      </vs-row>


    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'
import {VueTelInput} from 'vue-tel-input';
Vue.use(VueTelInput);
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'
import {countries} from "countries-list";

// Vuesax Component Framework
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'; // Vuesax
// Vue.use(Vuesax);


export default {
  name: 'account-page',
  components: {
    VueTelInput,
    vSelect,
    CountryFlag
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      maxDate: "",

      //sender here means the user
      senderFirstAndMiddleName: "",
      senderLastName: "",
      senderDateOfBirth: "",
      senderPhone: "",
      senderCountry: "",
      senderCity: "",
      senderAddress: "",
      senderPostalCode: "",
      senderProvince: "",

      senderDetailsExist: false,

      validationSenderFirstAndMiddleNameError: false,
      validationSenderLastNameError: false,
      validationSenderDateOfBirthError: false,
      validationSenderPhoneError: false,
      validationSenderCountryError: false,
      validationSenderCityError: false,
      validationSenderAddressError: false,
      validationSenderPostalCodeError: false,
      validationSenderDateOfBirthMinimumAgeError : false,

      phoneValidationError : false,

      phoneValidation: {
        mode:'international',
        inputOptions: {
          showDialCode: false,
        },
        placeholder: "",
        disabledFetchingCountry: true,
        enabledCountryCode: false,
        validCharactersOnly: true,
        defaultCountry: this.$store.getters.userDetailsGetter && this.$store.getters.userDetailsGetter.profile.senderCountry
            ? this.$store.getters.userDetailsGetter.profile.senderCountry : ""
      },

      countryList: Object.entries(countries).map((item) => {
        return Object.assign(item[1], {countryCode: item[0]})
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).filter(item => ["AL", "BS", "BB", "BW", "KH", "IS", "JM", "MU", "MN", "MM", "NI", "PK", "PA", "SY", "UG", "YE", "ZW", "IR", "KP", "GS", "NG","RU","BY"].indexOf(item.countryCode) < 0),

      KYCStatus : false,

      isProfileSubmitted : false,
      profileSubmissionError : false,

      debugConsole : false,




    }
  },
  watch: {
    $route() {

    },

  },
  computed: {
    ...mapGetters(["userDetailsGetter"]),



  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {

    var eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    this.maxDate = new Date(eighteenYearsAgo).toISOString().split('T')[0];
    if (this.debugConsole) console.log("Eighteen Years Ago", new Date(eighteenYearsAgo).toISOString().split('T')[0]);

    //get the user profile
    this.getSenderDetails().then(res => {
    if(this.debugConsole)  console.log("get sender/user details in trading profile page", res.data);

      this.senderFirstAndMiddleName = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderFirstAndMiddleName === null ? "" : this.userDetailsGetter.profile.senderFirstAndMiddleName)
      this.senderLastName = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderLastName === null ? "" : this.userDetailsGetter.profile.senderLastName)
      this.senderDateOfBirth = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderDateOfBirth === null ? "" : this.userDetailsGetter.profile.senderDateOfBirth)
      this.senderPhone = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderPhone === null ? "" : this.userDetailsGetter.profile.senderPhone)
      this.senderCountry = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderCountry === null ? "UK" : this.userDetailsGetter.profile.senderCountry)
      this.senderCity = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderCity === null ? "" : this.userDetailsGetter.profile.senderCity)
      this.senderAddress = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderAddress === null ? "" : this.userDetailsGetter.profile.senderAddress)
      this.senderPostalCode = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderPostalCode === null ? "" : this.userDetailsGetter.profile.senderPostalCode)
      this.senderProvince = this.userDetailsGetter.profile && (this.userDetailsGetter.profile.senderProvince === null ? "" : this.userDetailsGetter.profile.senderProvince)

    }).catch(err => {
      console.log("error in getting sender/user details in trading profile page", err);
    })

  },
  beforeDestroy() {

  },
  methods: {
    ...mapActions(["getSenderDetails","submitSenderDetails"]),

    phoneValidationMethod({isValid})
    {
      if(this.debugConsole) console.log("Is Valid", isValid);
      if(isValid)
      {
        this.phoneValidationError = false;
      }else
      {
        this.phoneValidationError = true;
      }

    },

    submitProfile(){

      //validation
      if (!this.senderFirstAndMiddleName) {
        this.validationSenderFirstAndMiddleNameError = true;
      } else {
        this.validationSenderFirstAndMiddleNameError = false;
      }

      if (!this.senderLastName) {
        this.validationSenderLastNameError = true;
      } else {
        this.validationSenderLastNameError = false;
      }

      if (!this.senderDateOfBirth) {
        this.validationSenderDateOfBirthError = true;
        this.validationSenderDateOfBirthMinimumAgeError = false;
      } else if(this.senderDateOfBirth && this.senderDateOfBirth > this.maxDate){
        this.validationSenderDateOfBirthMinimumAgeError = true;
        this.validationSenderDateOfBirthError = false;
      }
      else {
        this.validationSenderDateOfBirthError = false;
        this.validationSenderDateOfBirthMinimumAgeError = false;
      }

      if (!this.senderPhone || this.senderPhone && this.senderPhone.indexOf('+') < 0 || this.senderPhone && this.phoneValidationError) {
        this.validationSenderPhoneError = true;

      } else {
        this.validationSenderPhoneError = false;
      }

      if (!this.senderCountry) {
        this.validationSenderCountryError = true;
      } else {
        this.validationSenderCountryError = false;
      }

      if (!this.senderCity) {
        this.validationSenderCityError = true;
      } else {
        this.validationSenderCityError = false;
      }

      if (!this.senderAddress) {
        this.validationSenderAddressError = true;
      } else {
        this.validationSenderAddressError = false;
      }

      if (!this.senderPostalCode) {
        this.validationSenderPostalCodeError = true;
      } else {
        this.validationSenderPostalCodeError = false;
      }

      // if (!this.senderProvince) {
      //     this.validationSenderProvinceError = true;
      // } else {
      //     this.validationSenderProvinceError = false;
      // }

      if (this.validationSenderFirstAndMiddleNameError || this.validationSenderLastNameError || this.validationSenderPhoneError || this.validationSenderDateOfBirthError || this.validationSenderDateOfBirthMinimumAgeError ||
          this.validationSenderCountryError || this.validationSenderCityError || this.validationSenderAddressError || this.validationSenderPostalCodeError) {
        return;
      }


      let payload = {};
      payload.first_name = this.senderFirstAndMiddleName;
      payload.last_name = this.senderLastName;
      payload.date_of_birth = this.senderDateOfBirth;
      payload.phone = this.senderPhone;
      payload.address_country = this.senderCountry;
      payload.address_city = this.senderCity;
      payload.address_lines = this.senderAddress;
      payload.postal_code = this.senderPostalCode;
      payload.address_state =  "";
      if (this.debugConsole) console.log("User Trading Profile data", payload);

      this.submitSenderDetails(payload).then(result => {
        if (this.debugConsole) console.log("Submit Sender Details Result in trading profile page", result);

        let payload = {}
        payload.senderFirstAndMiddleName = result.data && result.data.user_first;
        payload.senderLastName = result.data && result.data.user_last;
        payload.senderDateOfBirth = this.senderDateOfBirth;
        payload.senderPhone = this.senderPhone;
        payload.senderCountry = result.data && result.data.address_country;
        payload.senderCity = result.data && result.data.address_city;
        payload.senderAddress = result.data && result.data.address_lines;
        payload.senderPostalCode = result.data && result.data.postal_code;
        payload.senderProvince = result.data && result.data.address_state;


        this.isProfileSubmitted = true;

        // setTimeout(()=>{
        //   this.isProfileSubmitted = false;
        // },2000);

        this.$store.commit("UPDATE_USER_PROFILE", payload);

      }).catch(err => {
        // if (this.debugConsole)
        console.log("Submit Sender Details Result Error in trading profile page", err.response);
        this.isProfileSubmitted = false;
        this.profileSubmissionError = true

        if(err.response.data.errors)
        {
          if(err.response.data.errors.find(el => el.message === 'invalid' && el.path === "phone"))
          {
            this.validationSenderPhoneError = true;
          }
        }
      })


    },
    goBack(){
      this.$router.go(-1);
    }



  }
}

</script>
<style>


</style>